import dynamic from 'next/dynamic'
import Providers from "@/configuration/next-ui-provider";
import { GoogleAnalytics } from '@next/third-parties/google'

const Footer = dynamic(() => import("./footer/footer"), {ssr: false});
const MainHeader = dynamic(() => import("./main-header/main-header"), {ssr: false});
const TopHeader = dynamic(() => import("./top-header/top-header"), {ssr: false});
import Head from "next/head";
import {GlobalSettings} from "@/configuration/global-settings";
import { useEffect, useRef, useState } from 'react';
import { renderFallingFlower } from '@/utils/common-utils';
import styles from './falling-flower.module.css'

export default function Layout({ children, sharedService }) {
    const mainRef = useRef();
    const [isClient, setIsClient] = useState(false);
    const animationFlowerFall = sharedService.workingSpaceSettings?.animations?.length
        ? getAnimationByCode(
            sharedService.workingSpaceSettings.animations,
            "animation-flower-fall-1"
        )
        : { isActive: false, photo: "" };
    useEffect(() => {
        setIsClient(true);
        if (mainRef.current && animationFlowerFall.isActive) {
            setTimeout(() => {
                document.documentElement.style.setProperty(
                    "--main-height",
                    `${mainRef.current.scrollHeight}px`
                );
                document.documentElement.style.setProperty(
                    "--duration",
                    `${mainRef.current.scrollHeight / 100 + 10}s`
                );
            }, 1000);
        } else {
            document.documentElement.style.setProperty("--main-height", "0px");
            document.documentElement.style.setProperty("--duration", "0s");
        }
    }, [children, animationFlowerFall.isActive]);

    return (
        <>
            <Head>
                <link rel="icon" href="/assets/images/favicon.png" sizes="any"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta charSet="utf-8"/>
            </Head>
            <Providers>
                <TopHeader sharedService={sharedService}/>
                <MainHeader sharedService={sharedService}/>
                {/*TODO Add max width for large screen*/}
                <div className="!bg-custom overflow-x-hidden"
                    ref={animationFlowerFall.isActive ? mainRef : null}
                >
                    <div className="max-w-[1600px] mx-auto px-3 md:px-[50px]">
                        <main>{children}</main>
                        {process.env.NEXT_PUBLIC_ANALYTICS_ID && <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_ANALYTICS_ID}/>}
                    </div>
                    
                    {isClient &&
                        animationFlowerFall.isActive &&
                        renderFallingFlower(
                            styles,
                            animationFlowerFall.photo || "/icons/flower.svg"
                        )
                    }
                </div>
                <Footer sharedService={sharedService}/>
            </Providers>
        </>
    );
}