/** @type {import('tailwindcss').Config} */
import {nextui} from "@nextui-org/react";

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    "./node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}"
  ],
  theme: {
    extend: {
      colors: {
        primary: "var(--theme-primary)",
        secondary: "var(--theme-secondary)",
        "text-base": "var(--theme-text-base)",
        "custom": "var(--theme-custom-color)",
        'news-type' : '#A9A9A9',
        'news': '#273044',
        'search-clergies': '#0190C5',
        'salt-life' : '#3DB166',
        'gospel-button-text': '#72D5CD',
        'gospel-bg': '#F5F5F5',
        'gospel-text-blur': '#9B9B9B',
        'link': '#0080FF',
        'title-green': '#19B24B',
        'banner-title': '#364CA0',
        'title-section': '#163269',
        'normal-green-2': '#3DB066'
      },
      screens: {
        'md' : '960px',
        'lg' : '1200px',
        'mac': '1400px',
        'xl' : '1600px',
        '3xl': '1900px',
        "xxl" : '2000px',
        'tall': { 'raw': '(max-height: 800px)' },
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
        'cathedral': "url('/images/cathedral.jpeg')",
        'member-img-frame': "url('/images/members/Khung-L.svg')",
      },
    },
  },
  darkMode: "class",
  plugins: [nextui(), '@tailwindcss/line-clamp', require('tailwindcss-animated')],
}
